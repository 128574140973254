<template lang="pug">
.brackets(
  :class="takeModifier"
)
  template(
    v-for="(round, roundIndex) in takeRound"
  )
    .round(
      :key="roundIndex"
      :class="['round-' + (roundIndex+1) + ' ' + takeRoundModifier]"
    )
      .match(
        v-for="(match, matchIndex) in round"
        :class="[(match.length !== 1 ? 'match-' + (roundIndex+1) + '-' + (matchIndex+1) : 'match--last')  + ' ' + takeMatchModifier]"
      )
        .robot(
          v-for="(team, teamIndex) in match"
          :class="[(match.length === 1 ? 'robot--last' : 'robot-' + (roundIndex+1) + '-' + (matchIndex+1) + '-' + (teamIndex+1))  + ' ' + takeRobotModifier ]"
        )
          .content
            span {{team}}

</template>

<script>
// import {
//   mapGetters,
//   mapActions
// } from 'vuex'

export default {
  name: 'TournamentBrackets',
  props: {
    side: {
      type: String,
      required: false,
      validator: (value) =>
        ['Left', 'Right'].includes(value),
      default: () => 'Right'
    },
    bracketSize: {
      type: Number,
      default: 4
    },
    items: {
      type: Array,
      default: []
    }
  },
  data: () => ({
    localItems: [],
    defaultRounds: [256, 128, 64, 32, 16, 8, 4, 2, 1]
  }),
  computed: {
    takeModifier () {
      const block = 'brackets'
      const classList = []
      if (this.side === 'Left') {
        classList.push(`${block}--left`)
      } else {
        classList.push(`${block}--right`)
      }
      return classList
    },
    takeRoundModifier () {
      const block = 'round'
      const classList = []
      if (this.side === 'Left') {
        classList.push(`${block}--left`)
      } else {
        classList.push(`${block}--right`)
      }
      return classList
    },
    takeMatchModifier () {
      const block = 'match'
      const classList = []
      if (this.side === 'Left') {
        classList.push(`${block}--left`)
      } else {
        classList.push(`${block}--right`)
      }
      return classList
    },
    takeRobotModifier () {
      const block = 'robot'
      const classList = []
      if (this.side === 'Left') {
        classList.push(`${block}--left`)
      } else {
        classList.push(`${block}--right`)
      }
      return classList
    },
    takeRound () {
      if (this.items.length) {
        return this.items.filter(rounds => rounds.length <= this.bracketSize)
      }
      return []
    }
  },
  methods: {},
  filters: {},
  watch: {}
}
</script>

<style lang="sass" scoped>
  .brackets
    display: flex
    width: 100%
    &--right
      flex-direction: row-reverse

    .round
      display: flex
      flex-grow: 1
      flex-direction: column
      max-width: 200px
      .match
        display: flex
        flex-direction: column
        justify-content: center
        margin: 0 10px
        padding: 6px 6px
        flex-grow: 1
        position: relative
        &--last
          &.match--left, &.match--right
            &::before
              display: none

        &::before
          content: ""
          display: block
          width: 48px
          border-bottom: 2px solid #C4C4C4
          position: absolute
          top: 50%

        &--right
          &::before
            margin-right: 0px
            left: -38px

        &--left
          &::before
            margin-left: 0px
            right: -42px

        .robot
          display: flex
          flex-direction: column
          justify-content: center
          margin: 8px 16px
          flex-grow: 1
          position: relative
          &:nth-child(odd)
            &::after
              content: ""
              display: block
              border: 2px solid transparent
              height: 100%
              position: absolute
              width: 10px
              top: 50%

          &:nth-child(even)
            &::after
              content: ""
              display: block
              border: 2px solid transparent
              height: 100%
              position: absolute
              width: 10px
              bottom: 50%

          &--left
            &:nth-child(odd)
              &::after
                border-top-color: #C4C4C4
                border-right-color: #C4C4C4
                right: -16px

            &:nth-child(even)
              &::after
                border-bottom-color: #C4C4C4
                border-right-color: #C4C4C4
                right: -16px

          &--right
            &:nth-child(odd)
              &::after
                border-top-color: #C4C4C4
                border-left-color: #C4C4C4
                left: -13px

            &:nth-child(even)
              &::after
                border-bottom-color: #C4C4C4
                border-left-color: #C4C4C4
                left: -13px

          &--last
            &:nth-child(odd)
              &::after
                display: none

            &:nth-child(even)
              &::after
                display: none

          .content
            border: 2px solid black
            width: 100%
            height: 30px
            position: relative
            display: flex
            align-items: center
            justify-content: center
</style>
